export abstract class DomainException extends Error {
  abstract code: string;

  constructor(
    message: string,
    public readonly originalError?: any,
  ) {
    super(message);

    this.stack = `${this.stack}\n Caused by: ${originalError?.stack}`;
  }
}

export type GenericExceptionCode = 'unknown-error';

export const GeneralExceptionMessages: Record<GenericExceptionCode, string> = {
  'unknown-error': 'An unknown error occurred',
};

export class GenericException extends DomainException {
  constructor(
    public readonly code: GenericExceptionCode,
    originalError?: any,
  ) {
    super(GeneralExceptionMessages[code]);
    this.stack = originalError?.stack;
  }
}
